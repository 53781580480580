<template>
  <div>
    <consents-modal ref="consentsModal"/>
    <privilege-modal ref="privilegeModal"/>

    <b-navbar toggleable="sm"
              type="light"
              variant="light">

      <b-navbar-toggle right
                       target="nav_dropdown_collapse"/>

      <b-collapse id="nav_dropdown_collapse"
                  is-nav>

        <div id="inner">
          <call-info></call-info>
        </div>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown id="nav_bar_username"
                               :text="user_name"
                               right>

            <b-dropdown-item @click="goToUserProfile">
              profile
            </b-dropdown-item>

            <b-dropdown-item href="#"
                             @click="logout">
              log out
            </b-dropdown-item>

          </b-nav-item-dropdown>
        </b-navbar-nav>

      </b-collapse>

    </b-navbar>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CallInfo from '@/components/call_info';
  import ConsentsModal from '@/components/ConsentsModal';
  import privilegeModal from '@/components/userProfile/PrivilegeModal';

  export default {
    components: {
      CallInfo,
      ConsentsModal,
      privilegeModal,
    },
    data() {
      return { isPrivilegesModalOpen: true };
    },
    computed: {
      ...mapGetters('login', ['user']),
      user_name() {
        if (this.user.first_name && this.user.last_name) return `${this.user.first_name}\n${this.user.last_name}`;
        return 'N/A';
      },
    },
    methods: {
      logout() {
        this.$store.dispatch('login/logout')
          .finally(() => {
            this.$router.push('/login');
          });
      },
      goToUserProfile() {
        this.$router.push('/users/me');
      },
      privilegesAcceptanceRequired() {
        if (this.user.isPrivilegesAcceptanceRequired) {
          this.$refs.privilegeModal.openModal();
        }
      },
      async checkLoggedUserConsents() {
        await this.$store.dispatch('login/loadSelfConsents');
        const consentsFromLoggedUser = this.$store.getters['login/getLoggedUserAttribute']('consents');
        const hasUserRegistrationConsent = consentsFromLoggedUser ? consentsFromLoggedUser.register.state : false;
        if (!hasUserRegistrationConsent) {
          this.$refs.consentsModal.openModal();
        }
      },
    },
    mounted() {
      this.checkLoggedUserConsents();
      this.privilegesAcceptanceRequired();
    },
  };
</script>

<style lang="scss"
       scoped>

  .navbar {
    min-height: 80px;
  }

  #inner {
    display: table;
    margin: 0 auto;
    float: right;
    @media screen and (max-width: 450px) {
      visibility: hidden;
    }
  }

</style>
