const registerConsentText = 'I consent to the processing of my personal data for registration in '
  + 'the Solaris User Network\n system (SUN) in accordance with the Regulation of the European '
  + 'Parliament and of the\n Council (EU) 2016/679 of 27 April 2016 and in accordance with the\n'
  + '<a class="red-text"\n'
  + '    href="#/user/regulations">'
  + '    <i><u>\n'
  + '        information clause\n'
  + '    </u></i>'
  + '</a>'
  + '\n attached to my consent.';

const promotionConsentText = 'I agree to the processing of my personal data, including my face '
  + 'image, for promotional purposes on the\n SOLARIS\n website and in social media (Facebook) in '
  + 'accordance with the Act of 10 May 2018 on the protection of personal\n data (Journal of Laws '
  + 'of May 24, 2018, item 1000) and in accordance with the Regulation of the European\n Parliament '
  + 'and of the Council (EU) 2016/679 of 27 April 2016 and in accordance with the information clause\n'
  + 'attached to my consent.<br>\n'
  + '<hr>\n'
  + 'Furthermore, I declare that pursuant to art. 81 par. 1 of the act on copyright and related\n'
  + 'rights dated February 4, 1994 (Journal of Laws of 2004, item 880, as amended) I agree\n'
  + 'to use my image free of charge for the abovementioned purpose. In connection with the\n'
  + 'use of my image I will renounce:<br>\n'
  + '<ol>\n'
  + '    <li>the right to control and to approve the use of my image every time, including the right of\n'
  + '        approval in relation to the final form of the Jagiellonian University materials in which my\n'
  + '        image will be used;\n'
  + '    </li>\n'
  + '    <li>the right to always indicate me as a person visible on the Jagiellonian University\n'
  + '        materials, in particular to indicate my name and surname;\n'
  + '    </li>\n'
  + ' </ol>\n'
  + 'I understand that at any time I have the opportunity to inspect the material containing my image and it\n'
  + 'does not violate my personal rights.';

const teamShareConsentText = 'I consent to providing my personal data (name, surname, e-mail) to '
  + 'other users of the SUN system \n in order to enable possibility to be selected as a member of '
  + 'the research team\n of another user (Proposal Author) -\n'
  + '<span class="red--text">\n'
  + '    Lack of consent results in the inability to be invited to the research team.\n'
  + '</span>';

const communicationConsentText = 'I agree to receive (by e-mail) information on calls for '
  + 'applications for access\n to research infrastructure located at and/or related to NCPS '
  + 'SOLARIS and the CERIC-ERIC Consortium.';

const wishesConsentText = 'I agree to receive (by e-mail) occasional wishes';

export {
  registerConsentText,
  promotionConsentText,
  teamShareConsentText,
  communicationConsentText,
  wishesConsentText,
};
